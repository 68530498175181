import React from 'react'
import { graphql } from 'gatsby'

import { Layout } from '@components/layout'
import { Slice } from '@components/slice'

type Props = {
  data: any
  pageContext: any
}

const ContactPage: React.FC<Props> = props => {
  const metaData = props.data?.meta_data
  const seo = props.data?.seo
  let header = props.data?.header
  const footer = props.data?.footer
  const main = props.data?.main?.data
  const theme = props.pageContext?.theme
  const slices = main?.body
  header = { ...header, socialLinks: footer?.data?.social_links }


  return (
    <Layout
      seo={seo}
      header={header}
      footer={footer}
      theme={theme}
      activeMetaData={metaData}
    >
      <Slice allSlices={slices} />
    </Layout>
  )
}

export default ContactPage

export const pageQuery = graphql`
  query ContactPage($locale: String) {
    meta_data: prismicContactPage(lang: { eq: $locale }) {
      lang
      type
      alternate_languages {
        uid
        lang
      }
    }
    seo: prismicContactPage(lang: { eq: $locale }) {
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        open_graph_image {
          url(imgixParams: { width: 1200, height: 630 })
        }
      }
    }
    header: prismicHeader(lang: { eq: $locale }) {
      ...header
    }
    main: prismicContactPage(lang: { eq: $locale }) {
      data {
        body {
          ...heroWithImage2
          ...contactForm
        }
      }
    }
    footer: prismicFooter(lang: { eq: $locale }) {
      ...footer
    }
  }
`
